<template>
  <div>
    <div class="card mb-5 mb-xl-8">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">{{ nya }}</span>
          <span class="text-muted mt-1 fw-bold fs-7">{{ email }}</span>
        </h3>
        <div class="card-toolbar">
          <a @click="$router.go(-1)" class="btn btn-sm btn-light-primary">
            Volver atras
          </a>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body py-3">
        <!--begin::Table container-->
        <h3
          class="anchor fw-bolder mb-5"
          style="text-align: center;display: block;"
        >
          Registro de entradas
        </h3>
        <div class="table-responsive">
          <!--begin::Table-->
          <table class="table align-middle gs-0 gy-4">
            <!--begin::Table head-->
            <thead>
              <tr class="fw-bolder text-muted bg-light">
                <th class="text-center ps-4 min-w-400px rounded-start">
                  Fecha
                </th>
                <th class="min-w-400px text-center rounded-end">IP</th>
              </tr>
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody>
              <tr v-for="log of logs" :key="log.id">
                <td>
                  <span class="fw-bold text-center d-block fs-12">{{
                    log.logged_at
                  }}</span>
                </td>
                <td>
                  <span class="fw-bold text-center d-block fs-12">{{
                    log.ip_login
                  }}</span>
                </td>
              </tr>
            </tbody>
            <!--end::Table body-->
          </table>
          <!--end::Table-->
        </div>
        <!--end::Table container-->
      </div>
      <!--begin::Body-->
    </div>
  </div>
</template>

<script>
import JwtService from "@/core/services/JwtService";

export default {
  data() {
    return {
      nya: "Pedro solf",
      email: "pedro@ahoradoctor.com",
      userId: null,
      logs: []
    };
  },
  mounted() {
    this.userId = this.$route.params.id;
    const url =
      `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/logs?userId=` +
      this.userId;
    const currentToken = JwtService.getToken();

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentToken
      }
    })
      .then(res => res.json())
      .then(data => {
        this.nya = data.result.name + " " + data.result.lastname;
        this.email = data.result.email;
        this.logs = data.result.logs;
      });
  }
};
</script>

<style>
.post.d-flex.flex-column-fluid {
  max-height: 70vh !important;
  overflow: scroll;
}
</style>
